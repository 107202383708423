import { axios } from 'api'

export const create = async (payload) =>
  axios.post(`createOrderPageSet`, payload)

export const update = async (order_pageset_id, payload) =>
  axios.patch(`orderPageSets/${order_pageset_id}`, payload)

//no archive listed on dps-FE
// export const archive = async (pageset_id, payload) =>
//   axios.patch(`archivePageSet/${pageset_id}`, payload);

export const getByOrderLayoutId = async (order_layout_id) =>
  axios.post(`orderPageSets?order_layout_id=${order_layout_id}`)

export const getOrderPageSets = async (order_layout_id) =>
  axios.get(`orderPageSets?order_layout_id=${order_layout_id}`)

export const getHomeLayoutPageSets = async (layout_id) =>
  axios.get(`pageSets?layout_id=${layout_id}&custom_pages=true`)
