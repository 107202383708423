import { useZoom, useNavigatePage, useInsertItem } from './'

export default () => {
  const zoom = useZoom()
  const navigatePage = useNavigatePage()
  const insertItem = useInsertItem()

  return {
    zoomIn: () => zoom('add'),
    zoomOut: () => zoom('subtract'),
    previousPage: () => navigatePage('previous'),
    nextPage: () => navigatePage('next'),
    insertShape: () => insertItem('shape'),
    insertText: () => insertItem('text'),
    insertImage: (image) => insertItem('image', image),
    insertLine: () => insertItem('line'),
  }
}
