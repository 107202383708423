import React, { useEffect, useState } from 'react'
import {
  useRecoilValue,
  useRecoilCallback,
  useSetRecoilState,
  useRecoilState,
} from 'recoil'
import {
  pageZoomAtom,
  pageExceedsCanvasAtom,
  itemAtomFamily,
  itemIdAtom,
  watermarkAtomFamily,
  watermarkIdAtom,
  alignmentPointsAtom,
  pageLayoutAtom,
  rightMenuAtom,
  themeLayoutAtom,
} from 'atoms'
import { Flex } from 'components'
import { BoundingBox, Item, Divider, PageLoading, BackgroundDrag } from './'
import { VerticalAlignment } from './Alignment'
import * as S from './Page.styled'

export default ({
  children,
  pageRef,
  page: { product, pageProperties },
  theme,
  isPMRegisterBook,
  isEven,
  ...props
}) => {
  const zoom = useRecoilValue(pageZoomAtom)
  const pageExceedsCanvas = useRecoilValue(pageExceedsCanvasAtom)
  const itemIds = useRecoilValue(itemIdAtom)
  const watermarkIds = useRecoilValue(watermarkIdAtom)
  const setAlignmentPoints = useSetRecoilState(alignmentPointsAtom)
  const [pageLayout, setPageLayout] = useRecoilState(pageLayoutAtom)
  const activeMenu = useRecoilValue(rightMenuAtom)
  const [selectedItems, setSelectedItems] = useState([])
  const [hasMultiSelected, setHasMultiSelected] = useState(false)
  const themeLayout = useRecoilValue(themeLayoutAtom)

  const loadInitialData = useRecoilCallback(({ set }) => (items) => {
    const ids = []
    for (const item of items) {
      ids.push(item.id)
      set(itemAtomFamily(item.id), item)
    }
    set(itemIdAtom, ids)
  })

  const loadWatermarkData = useRecoilCallback(({ set }) => (watermarks) => {
    const ids = []
    for (const item of watermarks) {
      ids.push(item.id)
      set(watermarkAtomFamily(item.id), item)
    }
    set(watermarkIdAtom, ids)
  })

  useEffect(() => {
    product?.items && loadInitialData(product.items)
    product?.watermarks && loadWatermarkData(product?.watermarks)
    setAlignmentPoints(product?.alignmentPoints)

    const pageThemeLayout = product?.themeLayout
    const pageHasNoThemeLayout = !pageThemeLayout
    const pageThemeIdMatchesThemeId =
      pageThemeLayout?.theme_id === themeLayout?.theme_id

    const currentLayout =
      pageHasNoThemeLayout || !pageThemeIdMatchesThemeId
        ? themeLayout
        : product?.themeLayout

    setPageLayout(currentLayout)

    !pageThemeIdMatchesThemeId &&
      setPageLayout({
        ...currentLayout,
        theme_id: themeLayout?.theme_id,
      })
    // eslint-disable-next-line
  }, [product, setAlignmentPoints])

  const determineSelectedItems = (selected) => {
    const array = selectedItems
    selected ? array.push(true) : array.pop()
    setSelectedItems(array)
    setHasMultiSelected(selectedItems.length > 1)
    return selectedItems.length > 1
  }

  const editedImageMatchesTheme =
    themeLayout?.theme_id === pageLayout?.background_edits?.theme_id

  // background image url logic
  const backgroundImageUrl =
    pageLayout?.background_edits?.url && editedImageMatchesTheme
      ? pageLayout.background_edits.url
      : `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${
          themeLayout?.replacement_image
            ? themeLayout?.replacement_image
            : theme.image
        }`

  if (!product) return null

  return (
    <Flex
      justify={pageExceedsCanvas ? 'flex-start' : 'center'}
      height="100%"
      width="100%"
      data-bounding-box
    >
      <S.Page
        height={`${product?.productSize?.height}px`}
        minWidth={`${product?.productSize?.width}px`}
        padding={product?.padding}
        whiteBorder={product?.whiteBorder}
        zoom={zoom}
        pageExceedsCanvas={pageExceedsCanvas}
        ref={pageRef}
        theme={theme}
        themeLayout={themeLayout}
        backgroundImageUrl={backgroundImageUrl}
        pageLayout={pageLayout?.background_fill ? pageLayout : themeLayout}
        data-bounding-box
        preprinted={pageProperties.paperType === 'preprinted'}
        backgroundTabActive={activeMenu === 'Background'}
        pageTwo={
          pageProperties.pageNumber >= 2 &&
          !pageProperties?.product?.includes('Register')
        }
        {...props}
      >
        {isPMRegisterBook && <S.RBDiv product={product} isEven={isEven} />}
        <PageLoading />
        <BackgroundDrag
          backgroundImageUrl={backgroundImageUrl}
          pageLayout={pageLayout}
          preprinted={pageProperties.paperType === 'preprinted'}
          backgroundTabActive={activeMenu === 'Background'}
          pageTwo={
            pageProperties.pageNumber >= 2 &&
            !pageProperties?.product?.includes('Register')
          }
        />
        <S.PerforationGuide
          height={product?.productSize?.height}
          width={product?.productSize?.width}
          padding={product?.padding}
          whiteBorder={product?.whiteBorder}
          hideOverflow={activeMenu === 'Watermarks'}
          data-bounding-box
        >
          {watermarkIds?.map((id) => (
            <Item
              type="watermark"
              data-bounding-box={activeMenu !== 'Watermarks'}
              id={id}
              key={id}
            />
          ))}
        </S.PerforationGuide>
        {product?.dividers?.map((divider, index) => (
          <Divider
            key={index}
            left={divider?.left}
            whiteBorder={divider?.whiteBorder}
            padding={product?.padding?.left}
            data-bounding-box
          />
        ))}
        {product?.alignmentPoints?.vertical.map((alignment, index) => (
          <VerticalAlignment
            key={index}
            left={alignment}
            height={product?.productSize?.height}
            whiteBorder={product?.whiteBorder}
            data-bounding-box
          />
        ))}
        {/* NOTE: Remove horizontal alignment completely per Duane 01/18/22 https://trello.com/c/uOYYsjcN/1686-snap-to-center-guides */}
        {/* {product?.alignmentPoints?.horizontal.map((alignment, index) => (
          <HorizontalAlignment
            key={index}
            top={alignment}
            width={product?.productSize?.width}
            padding={product?.padding}
            data-bounding-box
          />
        ))} */}
        {itemIds?.map((id) => (
          <Item
            id={id}
            key={id}
            determineSelectedItems={determineSelectedItems}
            hasMultiSelected={hasMultiSelected}
          />
        ))}
        <BoundingBox />
        {children}
      </S.Page>
    </Flex>
  )
}
