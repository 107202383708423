import { useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { cases, customThemes } from 'api'
import { getUrlPath } from 'utils'
import {
  itemSelector,
  caseAtom,
  selectedItemAtom,
  itemAtomFamily,
  showPinturaAtom,
  backgroundImageAtom,
  pageThemeLayoutSelector,
  pageLayoutAtom,
  pageLoadingAtom,
  themeLayoutAtom,
} from 'atoms'

export default (update, editBackground) => {
  const _case = useRecoilValue(caseAtom)
  const selectedItem = useRecoilValue(selectedItemAtom)
  const item = useRecoilValue(itemAtomFamily(selectedItem))
  const backgroundImage = useRecoilValue(backgroundImageAtom)
  const setShowPintura = useSetRecoilState(showPinturaAtom)
  const setLoadingPage = useSetRecoilState(pageLoadingAtom)
  const themeLayout = useRecoilValue(themeLayoutAtom)

  // case image
  const setEditedImageURL = useSetRecoilState(
    itemSelector({ id: item.id, path: 'image.editedUrl' }),
  )
  const setImageURL = useSetRecoilState(
    itemSelector({ id: item.id, path: 'image.url' }),
  )
  const setImageEdits = useSetRecoilState(
    itemSelector({ id: item.id, path: 'image.edits' }),
  )
  const { id: caseId, filledImages } = _case || {}
  const {
    image: { url, edits, editedUrl } = {},
    properties: { caseImage } = {},
  } = item || {}

  // background image
  const pageThemeLayout = useRecoilValue(pageLayoutAtom)
  const setEditedBackground = useSetRecoilState(
    pageThemeLayoutSelector({ path: 'background_edits' }),
  )

  useEffect(() => {
    if (url?.includes('placeholder')) {
      setImageURL(
        `${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}${filledImages?.[caseImage]}`,
      )
    }
  })

  // methods
  const handleCaseImageDelete = async () => {
    if (editedUrl) {
      const editedUrlPath = getUrlPath(editedUrl)
      await cases.deleteEditedCaseImage(caseId, {
        filename: editedUrlPath,
      })
    }
  }

  const handleBackgroundImageDelete = async () => {
    if (pageThemeLayout?.background_edits?.url) {
      const editedUrlPath = getUrlPath(pageThemeLayout?.background_edits?.url)
      await customThemes.deleteEditedBackgroundImage(caseId, {
        filename: editedUrlPath,
      })
    }
  }

  const handleCaseImageUpload = (formData) =>
    cases.uploadEditedCaseImage(caseId, formData)

  const handleProcessing = () => {
    editBackground && setLoadingPage(true)
    editBackground && setShowPintura(false)
  }

  const handleEditedBackgroundImageUpload = async (formData) => {
    const newBackground = await customThemes.uploadEditedBackgroundImage(
      caseId,
      formData,
    )
    setLoadingPage(false)
    return newBackground
  }

  const handleCaseImageUploadSuccess = (imageState, data) => {
    setImageEdits(imageState)
    setEditedImageURL(data)
  }

  const handleEditedBackgroundImageUploadSuccess = (imageState, data) => {
    setEditedBackground({
      url: data,
      edits: imageState,
      theme_id: themeLayout?.theme_id,
    })
  }

  const handleDoneEditing = async ({ dest, imageState }) => {
    let formData = new FormData()
    formData.append('file', dest)

    // if there is already an edited image url, then delete
    editBackground ? handleBackgroundImageDelete() : handleCaseImageDelete()

    const { status, data } = editBackground
      ? await handleEditedBackgroundImageUpload(formData)
      : await handleCaseImageUpload(formData)

    if (data && status === 201) {
      editBackground
        ? handleEditedBackgroundImageUploadSuccess(imageState, data)
        : handleCaseImageUploadSuccess(imageState, data)
      setShowPintura(false)
      update()
    }
  }

  const handleFailure = () => {
    alert('Error processing')
    setLoadingPage(false)
  }

  return {
    url: editBackground
      ? `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${backgroundImage}`
      : url,
    edits: editBackground ? pageThemeLayout?.background_edits?.edits : edits,
    handleFailure,
    handleProcessing,
    handleDoneEditing,
  }
}
